import React, { useState } from "react"
import supabase from "../services/supabase"
import { Box, Button, Text, Flex, Input, Label } from "theme-ui"
import { i18nContext } from "../context/i18nContext"
import { InboundLink } from "./link"

const LoginForm = ({}) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, showError] = useState(false)

  const handleSubmit = event => {
    event.preventDefault()
    setLoading(true)
    showError(false)

    supabase.auth.signInWithPassword({ email, password }).then(({ error }) => {
      if (error) {
        setLoading(false)
        showError(true)
        console.log("error", error)
      } else {
        console.log("logged in")
      }
    })
  }

  return (
    <i18nContext.Consumer>
      {t => (
        <Box as="form" onSubmit={handleSubmit}>
          <Box sx={{ position: "relative" }}>
            <Label variant="label.kicker" htmlFor="email">
              Email
            </Label>
            <Input
              onChange={e => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              value={email}
              autoComplete="email"
              required
              sx={{ mb: 3 }}
            />
          </Box>
          <Flex
            sx={{
              justifyContent: "flex-end",
              mb: 2,
              fontSize: 1,
              fontWeight: "bold",
            }}
          >
            <InboundLink to="/reset-password/">{t.forgotPassword}</InboundLink>
          </Flex>
          <Box sx={{ position: "relative" }}>
            <Label variant="label.kicker" htmlFor="password">
              Password
            </Label>
            <Input
              onChange={e => setPassword(e.target.value)}
              type="password"
              name="password"
              id="password"
              value={password}
              autoComplete="current-password"
              required
              sx={{ mb: 4 }}
            />
          </Box>
          {error && (
            <Text as="p" sx={{ color: "red", mb: [3] }}>
              {t.wrongCredentials}
            </Text>
          )}
          <Button>{loading ? t.loading : t.login}</Button>
        </Box>
      )}
    </i18nContext.Consumer>
  )
}

export default LoginForm
