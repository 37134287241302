import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Box, Heading, Text } from "theme-ui"
import LoginForm from "../components/loginForm"
import { UserContext } from "../context/userContext"
import { i18nContext } from "../context/i18nContext"
import AuthLayout from "../components/authLayout"
import { InboundLink } from "../components/link"
import { Helmet } from "react-helmet"
import loginImage from "../images/login.jpg"

const Login = ({}) => {
  const user = useContext(UserContext)
  if (user && user.user) {
    navigate("/private/dashboard/", {
      replace: true,
    })
    return null
  } else {
    return (
      <AuthLayout locale={"en"} image={loginImage}>
        <i18nContext.Consumer>
          {t => (
            <>
              <Helmet>
                <title>{t.login} | NetZero Tube</title>
              </Helmet>
              <Box sx={{ maxWidth: ["100%", "100%", "80%"], margin: "0 auto" }}>
                <Heading
                  as="h1"
                  variant="h1"
                  sx={{
                    fontSize: [6, 7],
                    color: "dark",
                    fontWeight: 400,
                    strong: { fontWeight: 400, color: "primary" },
                    mb: [3, 4, 7],
                    mt: [7, 8, "10rem"],
                  }}
                  dangerouslySetInnerHTML={{ __html: t.loginTitle }}
                />
                <LoginForm />
                <Text
                  as="p"
                  sx={{
                    borderTop: "1px solid",
                    borderColor: "lightGray",
                    mt: [8],
                    pt: [3],
                  }}
                >
                  {t.noAccount}{" "}
                  <InboundLink to="/signup/">{t.signUp}</InboundLink>
                </Text>
              </Box>
            </>
          )}
        </i18nContext.Consumer>
      </AuthLayout>
    )
  }
}

export default Login
