import React, { useEffect } from "react"
import { i18nContext, languages } from "../context/i18nContext"
import { Box, Container, Grid, useColorMode } from "theme-ui"
import { InboundLink } from "./link"
import LogoNetZeroTube from "../images/netzero-tube.inline.svg"
import { getHomePath } from "../utils/path"

const AuthLayout = ({ locale, children, image }) => {
  const [colorMode, setColorMode] = useColorMode()

  useEffect(() => {
    setColorMode("home")
  }, [colorMode])

  return (
    <i18nContext.Provider value={languages[locale] || languages["en"]}>
      <Grid
        columns={[1, 1, "4fr 3fr", 2]}
        gap={[0, 0, 0, 0]}
        sx={{ minHeight: ["100vh"] }}
      >
        <Container variant="fw">
          <Box>
            <Box
              sx={{
                svg: { width: "auto", height: ["50px"] },
              }}
            >
              <InboundLink to={getHomePath(locale)}>
                <LogoNetZeroTube />
              </InboundLink>
            </Box>
            <Box>{children}</Box>
          </Box>
        </Container>
        <Box
          sx={{
            backgroundColor: "dark",
            backgroundImage: `url(${image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: ["50vh", "auto", "auto", "auto"],
          }}
        ></Box>
      </Grid>
    </i18nContext.Provider>
  )
}

export default AuthLayout
